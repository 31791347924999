import { useEffect } from 'react';

import { useRouter } from 'next/router';
import { usePrevious } from 'react-use';

import { useNiceModal } from '~/src/common/services/ModalsManager';
import { isTruthy } from '~/src/common/utils/guards';
import {
  GetCategories200,
  GetDetailBySlug200BreadcrumbsItem,
} from '~/src/queries/api-ecom/generated/api-ecom.schemas';

export const useCloseSideModals = () => {
  const { asPath } = useRouter();

  // On ferme certaines modales lors de l'ouverture d'une fiche produit/recette (=changement d'url)
  const shouldClose = asPath !== usePrevious(asPath);

  const { remove: closeCartModal } = useNiceModal('cart-modal');
  const { remove: closeLastMinutePurchasesPanel } = useNiceModal('last-minute-purchase-panel');

  useEffect(() => {
    if (shouldClose) {
      closeCartModal();
      closeLastMinutePurchasesPanel();
    }
  }, [shouldClose, closeCartModal, closeLastMinutePurchasesPanel]);
};

interface Options {
  category?: string;
  subcategory?: string;
  eventSource?: string;
}

export type CatalogContentLinkQueryParams = {
  categorySlug?: string;
  slug: string;
} & Options;

/**
 * It returns generated catalog content link page
 * @param slug String for the slug property of a catalog content
 * @param options Object with category, subcategory and eventSource properties (optionnals)
 */
const useCatalogContentLink = (slug: string, { category, subcategory, eventSource }: Options) => {
  const { query: routerQuery } = useRouter<{
    categorySlug?: string;
  }>();

  return {
    ...routerQuery,
    slug,
    category,
    subcategory,
    eventSource,
  };
};

export const useProductLink = (slug: string, { category, subcategory, eventSource }: Options) => {
  const query = useCatalogContentLink(slug, { category, subcategory, eventSource });

  const as = `/produit/${slug}`;

  return { as, query };
};

export const useRecipeLink = (slug: string, { category, subcategory, eventSource }: Options) => {
  const query = useCatalogContentLink(slug, { category, subcategory, eventSource });

  const as = `/recette/${slug}`;

  return { as, query };
};

export const useBreadcrumbs = (
  categories?: GetCategories200,
  defaultValue?: GetDetailBySlug200BreadcrumbsItem[],
) => {
  const {
    query: { subcategory, category, categorySlug },
  } = useRouter<CatalogContentLinkQueryParams>();

  // L'accueil a un 'categorySlug' de '' (pathname = /)
  const isHome =
    !categorySlug ||
    categories?.items.find(
      cat => cat.tags?.includes('Home') && category?.trim() === cat.name.trim(),
    );

  const categoriesBreadcrumbs = subcategory
    ? [!isHome ? category : null, subcategory].filter(isTruthy).map(bc => {
        const cat = categories?.items.find(c => c.name.trim() === bc.trim());

        return {
          id: cat?.id,
          name: cat?.name,
          slug: cat?.slug,
          enabled: Boolean(cat?.name && cat?.slug),
        };
      })
    : defaultValue || [];

  return categoriesBreadcrumbs;
};

import styled from 'styled-components';

import { PICKER_SECTION_HEIGHT } from '~/src/common/components/GridCard/layout';

export const TopSectionContainer = styled.div`
  height: calc(calc(100% - ${PICKER_SECTION_HEIGHT}px) + 0.5px);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

import { useFeatureFlag } from '~/src/common/services/FeatureFlag';
import I18n from '~/src/common/services/I18n';

import * as S from './layout';
import Star from './Star';
import { RatingType } from './types';
import { getStarsShape } from './utils';

interface Props {
  rating?: RatingType;
  className?: string;
  showLabel?: boolean;
  onClick?: () => void;
}

const Rating = ({ rating, className, showLabel, onClick }: Props) => {
  // Notes et avis clients
  const showRating = useFeatureFlag('widgets-trustpilot') && rating != null;
  if (!showRating) return null;
  const formattedAverageRating = rating.average.toFixed(1).replace('.', ',');

  return (
    <S.RatingContainer className={className} onClick={onClick}>
      <S.StarsContainer>
        {getStarsShape(rating.average).map((mode, index) => (
          // more performant than using a generated random key and the order won't change

          <Star key={`${mode}-${index}`} mode={mode} />
        ))}
      </S.StarsContainer>
      <p>{formattedAverageRating}</p>
      <p>({showLabel ? I18n.t('common.review', { count: rating.nbRatings }) : rating.nbRatings})</p>
    </S.RatingContainer>
  );
};

export default Rating;

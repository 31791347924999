import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const PriceContainer = styled.div<{
  $isPromo: boolean;
}>`
  display: flex;
  margin-left: 2px;
  width: max-content;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.common.TEXTS};

  & > span:first-child {
    font-size: 18px;
    line-height: 24px;
  }

  & > span:last-child {
    font-size: 16px;
    line-height: 24px;
  }

  background-color: ${({ theme }) => theme.palette.common.TRANSPARENT};

  ${({ $isPromo, theme }) =>
    $isPromo
      ? css`
          padding: 0 2px;
          margin-left: 0;
          background-color: ${theme.palette.common.PROMOTION};
          color: ${theme.palette.common.TEXTS};
          border-radius: 4px;
        `
      : ''}
`;

export const Decimals = styled.span`
  font-size: 12px;
  line-height: 20px;
`;

export const OriginalPrice = styled.span`
  margin-left: 4px;
  text-decoration-line: line-through;
  color: ${({ theme }) => theme.palette.common.TEXTS};
  font-size: 12px;
  line-height: 14px;
`;

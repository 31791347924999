import { BasicProduct } from '~/src/common/components/GridCard/ProductCard/type';
import { ImageFormatIds, ImageParams } from '~/src/common/typings/image';
import { getImageUrlFromFormat } from '~/src/common/utils/cloudinary';

import * as S from './layout';

interface Props {
  labels: BasicProduct['labels'];
  nbMaxDisplayed?: number;
}

const NB_LABELS_TO_DISPLAY = 2;

const Labels = ({ labels, nbMaxDisplayed = NB_LABELS_TO_DISPLAY }: Props) => {
  const labelsToDisplay = labels.slice(0, nbMaxDisplayed);

  return (
    <S.LabelsContainer>
      {labelsToDisplay.map(({ id, label, images }) => {
        const imageUrl = getImageUrlFromFormat(
          images[0],
          ImageFormatIds.Square,
          ImageParams.productLabels,
        );
        return imageUrl ? <S.Label key={id} src={imageUrl} alt={label} /> : null;
      })}
    </S.LabelsContainer>
  );
};

export default Labels;

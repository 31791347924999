import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 40px;
  min-width: 60px;
  max-width: 96px;
  background-color: ${({ theme }) => theme.palette.common.XLIGHT_GREEN};
  border-radius: 8px;
  padding: 0 4px;
  font-size: 11px;
  font-weight: 500;
  text-transform: capitalize;
`;

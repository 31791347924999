import styled from 'styled-components';

import { productCardTagStyle } from '~/src/common/components/GridCard/layout';
import OriginSVGFlag from '~/src/common/components/OriginFlag';
import type colors from '~/src/common/themes/main/colors';

export const TOP_RIGHT_ICON_WIDTH = 24;

export const PromoTag = styled.div<{
  $backgroundColor: keyof typeof colors;
  $color: keyof typeof colors;
}>`
  ${productCardTagStyle};

  background-color: ${({ theme, $backgroundColor }) => theme.palette.common[$backgroundColor]};
  color: ${({ theme, $color }) => theme.palette.common[$color]};

  &::first-letter {
    text-transform: uppercase;
  }

  /* 2 * 8 est le padding de la carte produit
   8 est la marge avec le bouton de bookmark */
  max-width: calc(100% - ${TOP_RIGHT_ICON_WIDTH + 2 * 8 + 8}px);
`;

export const CustomTag = styled.div`
  ${productCardTagStyle};

  background-color: ${({ theme }) => theme.palette.common.LIGHT_GREEN};
  color: ${({ theme }) => theme.palette.primary};

  /* 2 * 8 est le padding de la carte produit
   8 est la marge avec le bouton de bookmark */
  max-width: calc(100% - ${TOP_RIGHT_ICON_WIDTH + 2 * 8 + 8}px);
`;

export const OriginAndLabelsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 24px;
`;

export const Label = styled.img`
  width: 24px;
  height: 24px;
`;

export const LabelsContainer = styled.div`
  display: flex;
  align-items: center;

  & img {
    width: 24px;
    min-width: 24px;
  }

  & img:not(:last-child) {
    margin-right: 4px !important;
  }
`;

export const BottomInformationContainer = styled.p`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 0;
  color: ${({ theme }) => theme.palette.common.WHITE};

  & > span {
    display: flex;
    align-items: center;

    &:first-child {
      font-size: 12px;
      line-height: 16px;
    }

    & span:first-child {
      margin-right: 5px;
    }

    & span:last-child {
      position: relative;
      top: 1px;
      font-size: 12px;
      line-height: 15px;
    }
  }
`;

export const OriginFlag = styled(OriginSVGFlag)`
  margin-right: 4px;
  border-radius: 5px;
  width: 12px;
  height: 12px;
`;

export const Origin = styled.div`
  display: flex;

  & > p {
    display: inline-block;
    font-size: 12px;
    line-height: 12px;
    color: ${({ theme }) => theme.palette.common.WHITE};
    margin: 0;
  }
`;

export const ProductPriceGranularity = styled.p`
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: calc(100% - 16px);
  overflow: hidden;
  margin: 0;

  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.palette.common.DISABLED};

  &::first-letter {
    text-transform: capitalize;
  }
`;

export const BottomSectionInformationContainer = styled.div`
  width: 100%;
`;

export const CartPickerWrapper = styled.div`
  position: absolute;
  right: 8px;
`;

import React from 'react';

import FrenchFlag from '~/src/common/components/GridCard/ProductCard/assets/french-flag.svg';
import { HomeProduct } from '~/src/common/typings/product';

import FRENCH_LOCATIONS from './frenchLocations';
import * as S from './layout';

interface Props extends React.SVGProps<SVGSVGElement> {
  origin?: HomeProduct['origin'];
  defaultToText?: boolean;
}

const isFrenchOrigin = (country: HomeProduct['origin']) =>
  country != null && FRENCH_LOCATIONS.includes(country.trim());

const OriginFlag = ({ origin, defaultToText, ...rest }: Props) => {
  if (origin == null) return null;

  if (isFrenchOrigin(origin)) return <FrenchFlag {...rest} />;

  return defaultToText ? <S.Root className={rest.className}>{origin}</S.Root> : null;
};

export default OriginFlag;

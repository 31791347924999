import { useEffect, useRef } from 'react';

const DEFAULT_EVENTS = ['mousedown', 'touchstart'];

// https://github.com/mantinedev/mantine/blob/master/src/mantine-hooks/src/useClickOutside/useClickOutside.ts
const useClickOutside = <T extends HTMLElement>(handler: () => void, isEnabled = true) => {
  const ref = useRef<T>(null);
  const areListenersEnabled = useRef(false);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any  -- auto-ignored when updating eslint
    const listener = (event: any) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment  -- auto-ignored when updating eslint
      const { target } = event ?? {};
      if (ref.current && target instanceof Element && !ref.current.contains(target)) {
        handler();
      }
    };

    if (isEnabled && !areListenersEnabled.current) {
      areListenersEnabled.current = true;
      DEFAULT_EVENTS.forEach(fn => document.addEventListener(fn, listener));
    } else if (areListenersEnabled.current) {
      areListenersEnabled.current = false;
      DEFAULT_EVENTS.forEach(fn => document.removeEventListener(fn, listener));
    }

    return () => {
      DEFAULT_EVENTS.forEach(fn => document.removeEventListener(fn, listener));
    };
  }, [ref, handler, isEnabled]);
  return ref;
};

export default useClickOutside;

import styled, { css } from 'styled-components';

import Button from '~/src/common/components/Button';
import { ButtonContent } from '~/src/common/components/Button/layout';

export const Container = styled.div`
  position: relative;
`;

export const PickerQuantity = styled.span`
  width: 32px;
  height: 40px;
  padding: 12px 0;

  text-align: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;

  color: ${({ theme }) => theme.palette.common.WHITE};
  background-color: ${({ theme }) => theme.palette.common.PRIMARY};
`;

const updateQuantityButtonStyle = css`
  height: 40px;
  min-width: 32px;
  padding: 0 4px;

  &[disabled],
  &[disabled]:hover {
    cursor: not-allowed;
    background: ${({ theme }) => theme.palette.common.DISABLED};
    color: ${({ theme }) => theme.palette.common.WHITE};
    border-color: ${({ theme }) => theme.palette.common.DISABLED};
  }

  padding-right: 0;
  padding-left: 0;
`;

export const AddQuantityButton = styled(Button)`
  ${updateQuantityButtonStyle};
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
`;

export const RemoveQuantityButton = styled(Button)`
  ${updateQuantityButtonStyle};

  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
`;

export const PickerButtonsWrapper = styled.div<{ $isOpen: boolean }>`
  position: relative;
  top: 0;
  right: 0;
  height: 40px;
  overflow: hidden;
  border-radius: 8px;
  transition: min-width 0.15s;

  ${({ $isOpen }) =>
    $isOpen
      ? css`
          min-width: 96px;
          z-index: 13;
        `
      : css`
          min-width: 40px;
        `}
`;

export const PickerButtonsContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  border-radius: 8px;

  width: 96px;
  z-index: 5;
`;

export const FoldedPickerButton = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 5;

  width: 40px;
  min-width: unset;
  height: 40px;
  padding-right: 0;
  padding-left: 0;

  & > div > span {
    line-height: 16px;
  }

  & ${ButtonContent} {
    line-height: 16px;
    font-size: 16px;
  }
`;

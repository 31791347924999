import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  inset: auto 0 64px auto;

  display: flex;
  align-items: center;
  flex-direction: column;

  gap: 4px;
  padding: 8px;

  border-radius: 4px;
  background-color: ${({ theme }) => theme.palette.common.WHITE};

  transition: opacity 0.4s ease-in-out;

  &::after {
    content: '';
    position: absolute;
    inset: auto 48px 0 auto;

    display: block;
    width: 10px;
    height: 10px;

    transform: translate(50%, 50%) rotate(45deg);
    background: inherit;
  }
`;

export const RemainingQuantityLabel = styled.p`
  margin: 0;
  padding: 0;

  font-size: 12px;
  font-weight: 500;
  line-height: 16px;

  text-wrap: balance;
  text-align: center;

  & > span {
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
    margin-right: 2px;
  }
`;

export const RewardProgression = styled.div<{ progression: number }>`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.common.PROMOTION};
  width: ${({ progression }) => progression}%;
  transition: width 0.4s ease-in-out;
`;

export const RewardContainer = styled.div`
  position: relative;
  width: 100%;
  height: 4px;
  background-color: ${({ theme }) => theme.palette.common.PROMOTION_LIGHT};
  border-radius: 4px;
  overflow: hidden;

  & > *:not(${RewardProgression}) {
    z-index: 10;
  }
`;

import React, { SyntheticEvent, useContext } from 'react';

import CartPicker from '~/src/common/components/CartPicker/CartPicker';
import StyledPrice from '~/src/common/components/GridCard/ProductCard/AvailableProductCard/StyledPrice/StyledPrice';
import { ProductCardContext } from '~/src/common/components/GridCard/ProductCard/ProductCardContext';
import { getPriceGranularity } from '~/src/common/utils/prices';
import { AllowedProductForCard } from '~/src/typings/products/types';

import { BottomSectionContainer } from '../../layout';

import * as S from './layout';

interface ActionCardProps {
  product: AllowedProductForCard;
  isDeliverySetup: boolean;
  onDisabledPickerClick: (quantity: number, increment: number) => void;
  cartQuantity: number;
}

const PickerSection = ({
  product,
  isDeliverySetup,
  cartQuantity,
  onDisabledPickerClick,
}: ActionCardProps) => {
  const { availableQuantity, itemPrice, itemDefinition, promo, packSize } = product;

  const isPromo = promo != null;
  const isBatchDiscount = isPromo && promo.mechanism === 'BATCH_DISCOUNT';
  const price = isBatchDiscount ? promo.itemOriginalPrice * packSize : itemPrice * packSize;

  const priceGranularity = getPriceGranularity(itemDefinition, { displayWeight: true, packSize });

  const stopOnProductCartClickEventPropagation = (e: SyntheticEvent) => {
    // Utilisé pour ne pas jouer le onClick du composant ProductCard
    // TODO refacto le composant pour mieux gérer ça
    //  => si on a un onClose sur la carte produit il ne faudrait pas le jouer lors d'un click sur le picker
    e.stopPropagation();
  };

  const { onUpdateQuantity } = useContext(ProductCardContext);

  return (
    <BottomSectionContainer onClick={stopOnProductCartClickEventPropagation}>
      <S.BottomSectionInformationContainer>
        <StyledPrice
          price={price}
          promo={
            promo ? { ...promo, itemOriginalPrice: promo.itemOriginalPrice * packSize } : undefined
          }
        />
        {priceGranularity ? (
          <S.ProductPriceGranularity>{priceGranularity.toLowerCase()}</S.ProductPriceGranularity>
        ) : null}
      </S.BottomSectionInformationContainer>
      <S.CartPickerWrapper>
        <CartPicker
          product={product}
          availableQuantity={availableQuantity}
          onDisabledClick={onDisabledPickerClick}
          onUpdateCartQuantity={onUpdateQuantity}
          cachedQuantity={cartQuantity}
          enabled={isDeliverySetup}
          packSize={packSize}
        />
      </S.CartPickerWrapper>
    </BottomSectionContainer>
  );
};

export default PickerSection;
